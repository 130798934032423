import request from '../utils/request'
//订单可用优惠券列表
export const payCouponList = (pay_order_number) => {
    return request({
        method: 'post',
        url: '/coupon/pay-coupon-list',
        data: {pay_order_number},
    })
}
//优惠券列表
export function couponList(data){
    return request({
        method: 'post',
        url: '/coupon/coupon-list',
        data,
    })
}
//领取优惠券
export function receiveCoupon(coupon_id){
    return request({
        method: 'post',
        url: '/coupon/receive-coupon',
        data:{coupon_id},
    })
}
